import React from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Alert, Button, Card, Col, Container, Dropdown, DropdownButton, Form, FormControl, Image, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import BasicPage from '../../components/basicPage';
import DialogBox from '../../components/dialogBox';
import LaunchCard from '../../components/launchCard';
import { getApiUrl, getBlobUrl } from '../../config/api';
import SquareImage from '../../components/squareImage';
import ConnectToOculusButton from '../../components/connectToOculusButton';
import NotMergedAlert from '../../components/notMergedAlert';
import { getIconSrc } from '../../utils/entitycache';

type RedeemCodePageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

enum RedeemingState {
    redeemConfirm,
    redeeming,
    redeemSuccess,
    redeemFail,
    redeemUnlinked
}
type RedeemCodePageState = {
    resultText: string,
    redeemingState: RedeemingState,
    redeemedUnlock: Unlocked | null,
    redeemCode: string,
    redeemType: "building_slot" | "domain" | "metacoin" | null,
};

type RedeemCodeResult = {
    success: boolean,
    msg: string,
    unlocked: any
}

type Unlocked = {
    metacoin?: number
    name?: string
    uri: string
}

class RedeemCodePage extends React.Component<RedeemCodePageProps, RedeemCodePageState> {
    constructor(props: RedeemCodePageProps) {
        super(props);
        this.state = {
            resultText: "",
            redeemingState: RedeemingState.redeemConfirm,
            redeemedUnlock: null,
            redeemCode: "",
            redeemType: null,
        };
    }

    async componentDidMount(): Promise<void> {
        try {
            //match against url to pull out domain + location
            const match = matchPath<{ code: string }>(this.props.history.location.pathname, {
                path: '/redeemcode/:code',
            })
            if (!match) {
                throw new Error("Invalid path");
            }

            const code = match.params.code;
            const code_info = {
                oculus_account_required: false,
            }
            if (code.length < 12) {
                // old format
                code_info.oculus_account_required = true;
            } else {
                // new format - check 1st 2 chars for reserved info
            }

            const { multiverse: { user } } = this.props;
            if(code_info.oculus_account_required && !user?.oculusaccount) {
                this.setState({resultText:"You must have a linked Oculus account to redeem a code. Please launch in VR to do this.", redeemingState:RedeemingState.redeemUnlinked})
                return;
            }

            this.setState({redeemCode: code})

        } catch(err) {
            this.setState({resultText: "error", redeemingState:RedeemingState.redeemFail }) //err as string} )
        }
    }

    //prelink confirmation dialog
    renderConfirmRedeemDialog() {
        const { multiverse: { user } } = this.props;
        const { redeemingState } = this.state;
        const iconUrl = getIconSrc(user);

        return ( user &&
            <Modal show={redeemingState === RedeemingState.redeemConfirm} onHide={this.onHomeClick} centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Redeem Code</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{margin:'auto'}}>
                    <p>Are you sure you want to permanently redeem this code with the account</p>
                    <p><SquareImage src={iconUrl} roundedCircle style={{ height: '5em', width: '5em', padding:'5%', verticalAlign:'middle'}}/><strong style={{paddingLeft: '5%'}}>{user?.nickname}</strong>?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={this.handleConfirmRedeemOK}>
                        Yes
                    </Button>
                    <Button variant="secondary" onClick={this.onHomeClick}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        ) 
    }

    handleConfirmRedeemOK = async() => {
        const { multiverse: { user } } = this.props;
        const result = await this.props.multiverse.post<RedeemCodeResult>(`/v2/users/${user!.id}/redeemcode/${this.state.redeemCode}`)

        const state: RedeemCodePageState = {
            resultText: result.msg,
            redeemingState: result.success?RedeemingState.redeemSuccess:RedeemingState.redeemFail,
            redeemedUnlock: null,
            redeemType: null,
            redeemCode: this.state.redeemCode
        }

        if(result.success) {
            if("building_slot" in result.unlocked) {
                state.redeemedUnlock = {
                    name: result.unlocked["location_name"],
                    uri: result.unlocked["location_uri"]
                }
                state.redeemType = "building_slot"
            }
            else if("domain" in result.unlocked)  {
                state.redeemedUnlock = {
                    name: result.unlocked["domain_name"],
                    uri: result.unlocked["domain_uri"]
                }
                state.redeemType = "domain"
            }
            else if("metacoin" in result.unlocked) {
                state.redeemedUnlock = {
                    metacoin: result.unlocked["metacoin"],
                    uri: result.unlocked["domain_uri"]
                }
                state.redeemType = "metacoin"
            }
        }

        this.setState(state)
    }


    onViewClick = () => {
        // go to new apartment page
        if(this.state.redeemedUnlock) {
            this.props.history.push(`/domains/${this.state.redeemedUnlock.uri}`)
        }
    } 
    onHomeClick = () => {
        this.props.history.push(`/profile`)
    }

    renderRedeemFailCard = () => {
        const showCard = this.state.redeemingState === RedeemingState.redeemFail;
        return (<Modal show={showCard} onHide={this.onHomeClick} centered={true}>
            <Modal.Header closeButton className='hc-error'>Error Redeeming Code</Modal.Header>
            <Modal.Body>
                <div>{this.state.resultText}</div><br/>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={this.onHomeClick}>Home</Button>
            </Modal.Footer>
        </Modal>)
    }

    renderRedeemUnlinkedCard = () => {
        const unLinked = this.state.redeemingState === RedeemingState.redeemUnlinked;
        return (<Modal show={unLinked} onHide={this.onHomeClick} centered={true}>
            <Modal.Header className='hc-error' closeButton>Error Redeeming Code</Modal.Header>
            <Modal.Body>
                <div>{this.state.resultText}</div><br/>
                <div><NotMergedAlert/></div>
            </Modal.Body>
            </Modal> )
    }

    renderRedeemingCard = () => {
        const showCard = this.state.redeemingState === RedeemingState.redeeming;
        return (showCard && <Card className="mt-2">
            <Card.Header>Redeeming Code...</Card.Header>
            <Card.Body>
                <Spinner animation="border" role="status" className="mr-2"/>
            </Card.Body>
            </Card>)
    }

    renderRedeemResultCard = () => {
        const showCard = this.state.redeemingState === RedeemingState.redeemSuccess;
        const { multiverse: { user } } = this.props;
        
        const redeemMessage = this.state.redeemType === "building_slot" 
        ? `You have been awarded a new apartment at ${this.state.redeemedUnlock?.name}!`
        : this.state.redeemType === "domain"
        ? `You have been awarded a new metaverse at ${this.state.redeemedUnlock?.uri}!`
        : this.state.redeemType === "metacoin"
        ? `You have been awarded ${this.state.redeemedUnlock?.metacoin} metacoin!`
        : `You have been redeemed your award!`;

        const buttonText = this.state.redeemType === "building_slot"
        ? "View & Customise Your Apartment"
        : this.state.redeemType === "domain" || this.state.redeemType === "metacoin"
        ? "View Your Metaverse"
        : null;

        return (<Modal show={showCard} onHide={this.onViewClick} centered={true}>
            <Modal.Header closeButton>Code Redeemed!</Modal.Header>
            <Modal.Body>
                <div style={{margin: 'auto'}}><h3>Congratulations<br/><b>{user?.nickname}</b>!</h3>
                
                <p>{redeemMessage}</p>
                </div>
            </Modal.Body>
            {buttonText !== null && (
                <Modal.Footer>
                    <Button variant="primary" onClick={this.onViewClick}>
                    {buttonText}
                    </Button>
                </Modal.Footer>
            )}
            </Modal> )
    }

    render() {
		return (
		<BasicPage>
            {this.renderConfirmRedeemDialog()}
            {this.renderRedeemingCard()}
            {this.renderRedeemResultCard()}
            {this.renderRedeemFailCard()}
            {this.renderRedeemUnlinkedCard()}
		</BasicPage>);
	}

}

export default withRouter(withMultiverseApi(RedeemCodePage));
