import React, { CSSProperties, FunctionComponent } from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
} from 'react-router-dom';

import {
    Col, Container, Navbar, Row,
} from 'react-bootstrap';
import Header from '../components/header';
import SideBar from '../components/sidebar';
import Footer from '../components/footer';
import CookieBanner from '../components/cookieBanner';

import Home from '../pages/home';
import PageNotFound from '../pages/pageNotFound';
import PrivateRoute from './privateRoute';
import ProfileSettings from '../pages/profileSettings';
import NotMergedAlert from '../components/notMergedAlert';
import DomainsPage from '../pages/domainsPage';
import DrivesPage from '../pages/drivesPage';
import ManageDomainsPage from '../pages/manageDomainPage';
import CreateDomainPage from '../pages/createDomainPage';
import DomainMembersPage from '../pages/domainMembersPage';
import LocationsPage from '../pages/locationsPage';
import EventsPage from '../pages/eventsPage';
import BrowseDrivePage from '../pages/browseDrivePage';
import DomainHomePage from '../pages/domainHomePage';
import AcceptInvitePage from '../pages/acceptInvitePage';
import AcceptMergePage from '../pages/acceptMergePage';
import PrivacyPolicyPage from '../pages/privacyPolicyPage';
import LinkAccountsPage from '../pages/linkAccountsPage';
import TermsCheck from './termsCheck'
import AuthorizedNav from '../components/authorizedNav';
import StreamPage from '../pages/streamPage';
import LocationPage from '../pages/locationPage';
import EntityPage from '../pages/entityPage';
import SetupDomainPage from '../pages/setupDomainPage';
import UpgradeDomainPage from '../pages/upgradeDomainPage';
import DomainStatsPage from '../pages/domainStatsPage';
import AdminMainPage from '../pages/adminMainPage';
import AdminCollectionPage from '../pages/adminCollectionPage';
import AdminDocumentPage from '../pages/adminDocumentPage';
import CreateLocationPage from '../pages/createLocationPage';
import IAPDisplay from '../pages/adminIAPDisplayPage';
import StatsPage from '../pages/adminStatsPage';
import AdminApprovalPage from '../pages/adminApprovalPage';
import AdminFeaturedPage from '../pages/adminFeaturedPage';
import AdvertsPage from '../pages/infiniverseAdvertsPage';
import RedeemCodePage from '../pages/redeemCodePage';
import AdminRedeemCodesPage from '../pages/adminRedeemCodesPage';
import AdminListRedeemCodesPage from '../pages/adminRedeemCodesPage/listcodes';
import CreateEventPage from '../pages/createEventPage';
import { AdminEventsPage } from '../pages/adminEventsPage';
import LandmarksPage from '../pages/landmarksPage';
import MapPage from '../pages/mapPage';
import PlotsPage from '../pages/plotsPage';
import AdminMissionConfigsPage from '../pages/adminMissionConfigsPage';
import AdminMissionEventConfigPage from '../pages/adminMissionEventConfigPage';
import AdminMissionEventCyclePage from '../pages/adminMissionEventCyclePage';
import AdminMissionEventPage from '../pages/adminMissionEventPage';
import AdminMissionsPage from '../pages/adminMissionsPage';
import AdminAvatarsPage from '../pages/adminAvatarsPage';
import AdminMissionEventsPage from '../pages/adminMissionEventsPage';
import AdminMVBotsPage from '../pages/adminMVBotsPage';

const CONTAINER_STYLE: CSSProperties = {
    minHeight: 'calc(100% - 135px)',
};

const AppRouter: FunctionComponent = () => (
    <>
        <Header />

        <div className="d-flex main-area">
            <SideBar/>
        <main className="p-0 flex-fill">
            <TermsCheck>

                <Switch>
                    {/* Public Routes - Can be accessed while not logged in */}
                    <Route path="/" exact component={Home} />
                    <Route path="/privacy" component={PrivacyPolicyPage} />

                    {/* Private Routes - If not logged in redirect you to homepage */}
                    <PrivateRoute path="/acceptinvite" component={AcceptInvitePage} />
                    <PrivateRoute path="/acceptmergerequest" component={AcceptMergePage} />
                    <PrivateRoute path="/adverts" component={AdvertsPage} />
                    <PrivateRoute path="/profile" component={ProfileSettings} />
                    <Route path="/map" component={MapPage} />
                    <PrivateRoute path="/domains/:id/manage" component={ManageDomainsPage} />
                    <PrivateRoute path="/domains/:id/members" component={DomainMembersPage} />
                    <Route path="/domains/:id/locations/:locid" component={EntityPage} />
                    <Route path="/domains/:id/events/:locid" component={EntityPage} />
                    <Route path="/domains/:id/landmarks/:locid" component={EntityPage} />
                    <Route path="/domains/:id/plots/:locid" component={EntityPage} />
                    <PrivateRoute path="/domains/:id/events" component={EventsPage} />
                    <PrivateRoute path="/domains/:id/locations" component={LocationsPage} />
                    <PrivateRoute path="/domains/:id/landmarks" component={LandmarksPage} />
                    <PrivateRoute path="/domains/:id/plots" component={PlotsPage} />
                    <PrivateRoute path="/domains/:id/upgrade" component={UpgradeDomainPage} />
                    <PrivateRoute path="/domains/:id/stats" component={DomainStatsPage} />                    
                    <PrivateRoute path="/domains/:id/createlocation" component={CreateLocationPage} />            
                    <PrivateRoute path="/domains/:id/createevent" component={CreateEventPage}/>        
                    <Route path="/domains/:id" component={DomainHomePage} />
                    <PrivateRoute path="/domains" component={DomainsPage} />
                    <PrivateRoute path="/createdomain" component={CreateDomainPage} />
                    <PrivateRoute path="/drives" component={DrivesPage} />
                    <PrivateRoute path="/browsedrive" component={BrowseDrivePage} />
                    <PrivateRoute path="/linkaccounts" component={LinkAccountsPage} />
                    <PrivateRoute path="/stream" component={StreamPage} />
                    <PrivateRoute path="/setupdomain" component={SetupDomainPage} />
                    <PrivateRoute path="/redeemcode" component={RedeemCodePage} />

                    {/* Admin routes, all show error if not administrator */}
                    <PrivateRoute path="/admin" exact component={AdminMainPage} />
                    <PrivateRoute path="/admin/childproducts/:childproductid/missionevent/:id?" component={AdminMissionEventPage} />
                    <PrivateRoute path="/admin/childproducts/:childproductid/missioneventconfig/:configname?" component={AdminMissionEventConfigPage} />
                    <PrivateRoute path="/admin/childproducts/:childproductid/missioneventcycle/:id?" component={AdminMissionEventCyclePage} />
                    <PrivateRoute path="/admin/childproducts/:id/missionevents" component={AdminMissionEventsPage} />
                    <PrivateRoute path="/admin/childproducts/:id/missionconfigs" component={AdminMissionConfigsPage} />
                    <PrivateRoute path="/admin/childproducts/:id/missions" component={AdminMissionsPage} />
                    <PrivateRoute path="/admin/childproducts/:id/avatars" component={AdminAvatarsPage} />
                    <PrivateRoute path="/admin/redeemcodes" exact component={AdminRedeemCodesPage} />
                    <PrivateRoute path="/admin/redeemcodes/listcodes/*" component={AdminListRedeemCodesPage} />
                    <PrivateRoute path="/admin/approvals" exact component={AdminApprovalPage} />
                    <PrivateRoute path="/admin/featured" exact component={AdminFeaturedPage} />
                    <PrivateRoute path="/admin/featuredevents" exact component={AdminEventsPage}/>
                    {/*<PrivateRoute path="/admin/iapdisplay" exact component={IAPDisplay}/>*/}
                    <PrivateRoute path="/admin/stats" exact component={StatsPage}/>
                    <PrivateRoute path="/admin/*/:id" component={AdminDocumentPage} />
                    <PrivateRoute path="/admin/mvbots" component={AdminMVBotsPage} />
                    {/* add any new pages admin BEFORE this, otherwise shit just doesn't work!!! */}
                    <PrivateRoute path="/admin/" component={AdminCollectionPage} />

                    {/* Misc Routes */}
                    <Route path="*" exact component={PageNotFound} />
                </Switch>

            </TermsCheck>
        </main>
        </div>
        <Footer />
        <CookieBanner />
    </>
);
export default AppRouter;
