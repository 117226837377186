import React, { useState, useEffect } from 'react';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps, MultiverseBaseEntity, MultiverseDomain, MultiverseLocation, MultiverseRoom, MultiverseEvent, MultiverseUser } from '../../hoc/multiverseApiProvider';
import BasicPage from '../../components/basicPage';
import api from '../../utils/api';
import { Spinner } from 'react-bootstrap';
import { EntityHeaderCard } from './entityHeaderCard';
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import DiscoveryContentCard from '../../components/discoveryContentCard';
import eventBus from '../../utils/eventBus';
import { useEntity, useEditable } from '../../utils/entitycache';
import { LocationSettingsCard } from './locationSettingsCard';
import { SpecEditCards } from './specEditCards';
import { ApprovalBar } from '../locationPage/approvalbar';
import { CopyrightNotice } from './copyrightNotice';
import { EntityLaunchCard } from './entityLaunchCard';
import IsAuthenticated from '../../components/isAuthenticated';
import LocationsCard from '../../components/locationsCard';
import { EventSettingsCard } from './eventSettingsCard';
import './entitypage.css'
import { PublishBar } from './eventPublishBar';
import { EventLockedBar } from './eventLockedBar';
import { InfiniverseCard } from './infiniverseCard';
import { isProd } from '../../config/api';
import { ReadonlyNotice } from './readonlyNotice';
import MVBotsUserConfigCard from '../../components/mvbotsUserConfigCard';

export function EntityComponent(props: { targeturl: string }) {
    const [loading,setLoading] = useState<boolean>(true)

    const entity = useEntity<MultiverseBaseEntity>(props.targeturl)
    const editable = useEditable(props.targeturl);

    useEffect(() => {
        setLoading(entity != null)
        console.log(entity)
    }, [entity])

    const isOfType = (...types: string[]) => {
        return entity && types.includes(entity.type);
    }

    const [requireContent,setRequireContext] = useState<boolean>(false)

    return <>
        <EntityHeaderCard targeturl={props.targeturl} requireContent={requireContent}/>
        {isOfType("domain","location") && <EntityLaunchCard {...props}/>}
        {editable && <>
            {(entity && entity.readonly) && <ReadonlyNotice {...entity}/>}
            {isOfType("location","event") && <CopyrightNotice {...props}/>}
            {isOfType("event") && <EventLockedBar {...props}/>}
            {isOfType("event") && <PublishBar {...props} requirePublished={requireContent}/>}
            {isOfType("location") && <ApprovalBar {...props}/>}
            {isOfType("location") && <InfiniverseCard {...props}/>}
            {isOfType("location") && <LocationSettingsCard {...props}/>}
            {isOfType("event") && <EventSettingsCard {...props} onRequireContent={(rc)=>setRequireContext(rc)}/>}
            {isOfType("domain","location") && <MVBotsUserConfigCard {...props}/>}
            {isOfType("domain","location","event","landmark","plot") && <DiscoveryContentCard {...props} requireContent={requireContent}/>}
            {isOfType("domain","location","event") && <SpecEditCards {...props}/>}
            {isOfType("domain") && <LocationsCard {...props}/>}
        </>}

    </>
}

type EntityPageProps = {

} & WithMultiverseApiProps & RouteComponentProps;

type EntityPageState = {
};

class _EntityPage extends React.Component<EntityPageProps, EntityPageState> {
    constructor(props: EntityPageProps) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount(): Promise<void> {

    }

    render(): JSX.Element {
        const targ_url = this.props.history.location.pathname.replace("/domains/","");
        return (
            <BasicPage>
                <EntityComponent targeturl={targ_url}/>
            </BasicPage>
        )
    }
}
export const EntityPage = withRouter(withMultiverseApi(_EntityPage));

export default EntityPage;
