import React, { CSSProperties, FunctionComponent } from 'react';
import { Button, Col, Nav, Navbar } from 'react-bootstrap';
import IsAuthenticated from '../isAuthenticated';
import AuthorizedNav from '../authorizedNav';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import IsDeveloper from '../isDeveloper';
import { HasPermission } from '../hasPermission';

const STYLE:CSSProperties = {
  background: '#f6f7f8',
  borderRight: '1px solid #d0d8db',
  color: 'white',
  maxWidth: '15rem',
};

type SideBarProperties = {

} & WithMultiverseApiProps & RouteComponentProps

const _SideBar:FunctionComponent<SideBarProperties> = (props: SideBarProperties) => {

    if(document.location.href.includes("/admin")) {
        return (<>
            <IsAuthenticated>
                <Navbar className="d-none d-sm-block sidebar" bg="light" >
                    <Nav.Item><Link to={`/admin`}>Dashboard</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/users`}>Users</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/featured`}>Featured</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/domains`}>Domains</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/locations`}>Locations</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/plots`}>Owned Plots</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/files`}>Files</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/events`}>Events</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/stripecustomers`}>StripeCustomers</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/buildings`}>Buildings</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/approvals`}>Approvals</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/redeemcodes`}>Redeem Codes</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/featuredevents`}>FeaturedEvents</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/escrows`}>EscrowHoldings</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/archivedescrows`}>ArchivedEscrowHoldings</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/childproducts`}>Child Products</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/mvbots`}>MV Bots</Link></Nav.Item>
                    {
                        /*
                    <Nav.Item><Link to={`/admin/rooms`}>Rooms</Link></Nav.Item>
                     <Nav.Item><Link to={`/admin/legacylocations`}>LegLocations</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/legacyrooms`}>LegRooms</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/sessions`}>Sessions</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/shards`}>Shards</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/plans`}>Plans</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/stripewebhooks`}>StripeWebHooks</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/stripesubscriptions`}>StripeSubscriptions</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/witrequests`}>WitRequests</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/stringmatches`}>StringMatches</Link></Nav.Item>
                    <Nav.Item><Link to={`/admin/screenshots`}>Screenshots</Link></Nav.Item>
                        */
                    }
                </Navbar>
            </IsAuthenticated>
        </>)
    } else if(document.location.href.includes("/domains")) {
        return (<>
            {props.domain && (props.multiverse.user?.permissions.includes("superuser") || props.domain.domainPermissions?.includes("manage_domain")) &&
            <IsAuthenticated>
                <Navbar className="d-none d-sm-block sidebar" bg="light" >
                    <Nav.Item><Link to={`/domains/${props.domain.uri}`}>Home</Link></Nav.Item>
                    <Nav.Item><Link to={`/domains/${props.domain.uri}/manage`}>Manage</Link></Nav.Item>
                    <Nav.Item><Link to={`/domains/${props.domain.uri}/members`}>Members</Link></Nav.Item>
                    {props.domain.uri !== "enterprise" && <Nav.Item><Link className="upgrade-link" to={`/domains/${props.domain.uri}/upgrade`}>Upgrade</Link></Nav.Item>}
                    <HasPermission permission="create_events">
                        <Nav.Item><Link to={`/domains/${props.domain.uri}/events`}>Events</Link></Nav.Item>            
                    </HasPermission>
                    <IsDeveloper>
                        <Nav.Item><Link to={`/domains/${props.domain.uri}/landmarks`}>[D]Landmarks</Link></Nav.Item>            
                        <Nav.Item><Link to={`/domains/${props.domain.uri}/stats`}>[D]Stats</Link></Nav.Item>            
                    </IsDeveloper>
                    <Nav.Item><Link to={`/domains/${props.domain.uri}/plots`}>Plots</Link></Nav.Item>            
                </Navbar>
            </IsAuthenticated>}
        </>);
    } else {
        return <></>
    }

}
const SideBar = withRouter(withMultiverseApi(_SideBar)); 
export default SideBar;
