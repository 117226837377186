
import { useState, useEffect } from "react"
import api from "../../utils/api"
import HeaderCard from "../../components/headerCard"
import React from "react"
import { getApiUrl, getBlobUrl, isProd } from "../../config/api"
import { Form, Container, Row, Col, Button } from "react-bootstrap"
import eventBus from "../../utils/eventBus"
import { useEntity, onEntityUpdated, postEntity } from "../../utils/entitycache"
import { MultiverseDomain, MultiversePlan, getDomainPlanInfo, MultiverseDomainMemberListResult } from "../../hoc/multiverseApiProvider"
import MemberBox from "../../components/domainFrontPage/memberBox"
import InviteMembersDialog from '../../components/inviteMembersDialog'
import { useHistory } from "react-router-dom"

export function EditableDomainHeader(props: { domain: MultiverseDomain }) {
    const domain = props.domain;
    const plan_info = getDomainPlanInfo(domain);
    const history = useHistory();
    const [showInviteMembers,setShowInviteMembers] = useState(false);


    //invite dialog completion callbacks
    const inviteMemberDialog_Complete = (newMembers: MultiverseDomainMemberListResult[]) => {
        setShowInviteMembers(false);
    }
    const inviteMemberDialog_Cancel = () => {
        setShowInviteMembers(false);
    }

    const onAddMemberClicked = () => {
        setShowInviteMembers(true);
    }

    const onUpgradeClicked = () => {
        history.push(`/domains/${domain.uri}/upgrade`);
    }
    
   return <>
   <InviteMembersDialog show={showInviteMembers} onCancel={inviteMemberDialog_Cancel} onComplete={inviteMemberDialog_Complete}/>
    <Container fluid>
        <Row>
            <Col xs="12" sm="6" className="text-xs-center text-sm-right">
                <div className="d-inline-block text-center">
                    <div  className='dhp-plan-box' style={{fontSize:18}}>
                        {plan_info.expiresText ? <span style={{ color: plan_info.expiresColor }}>{plan_info.expiresText}</span> : <>{plan_info.nameText}</>}
                    </div>
                    {!(domain.plan && ['enterprise', 'paperclip'].includes(domain.plan)) && <Button className="dhp-upgrade-button" variant='primary' onClick={onUpgradeClicked}>Upgrade</Button>}
                </div>
            </Col>
            <Col xs="12" sm="6" className="pt-2 text-xs-center text-sm-left">
                <MemberBox domainUri={domain.uri} onAddMemberClicked={onAddMemberClicked}/>
            </Col>
        </Row>
    </Container>
    </>
}
